<template>
    <div class="edhec-wrap">
        <div class="edhec-header">
            <Breadcrumbs
                :items="items"
            />
            <h1>{{ $t('edhecTitle') }}</h1>
        </div>
        <div class="edhec-text_wrap">
            <h3>{{ $t('edhecText1') }}</h3>
            <p class="edhec-text">
                <span class="list-number">1</span>
                <span>{{ $t('edhecText11') }}</span>
            </p>
            <p class="edhec-text">
                <span class="list-number">2</span>
                <span>{{ $t('edhecText12') }}</span>
            </p>
            <p class="edhec-text">
                <span class="list-number">3</span>
                <span>{{ $t('edhecText13') }}</span>
            </p>
            <h3>{{ $t('edhecText2') }}</h3>
            <p class="edhec-text">
                <span class="list-number">1</span>
                <span>{{ $t('edhecText21') }}</span>
            </p>
            <p class="edhec-text">
                <span class="list-number">2</span>
                <span>{{ $t('edhecText22') }}</span>
            </p>
            <h3>{{ $t('edhecText3') }}</h3>
            <p class="edhec-text">
                <span class="list-number">1</span>
                <span>{{ $t('edhecText31') }}</span>
            </p>
            <p class="edhec-text">
                <span class="list-number">2</span>
                <span>{{ $t('edhecText32') }}</span>
            </p>
            <p class="edhec-text">
                <span class="list-number">3</span>
                <span>{{ $t('edhecText33') }}</span>
            </p>

            <div class="edhec-info">
                <p>{{ $t('edecInfo1') }}</p>
                <a href="mailto:julia.dubina@edhec.edu">julia.dubina@edhec.edu</a>
                <p>{{ $t('edecInfo2') }}</p>
                <a href="https://master.edhec.edu/" target="_blank">https://master.edhec.edu/</a>
                <p>{{ $t('edecInfo3') }}</p>
                <a href="mailto:julia.dubina@edhec.edu">julia.dubina@edhec.edu</a>
            </div>

        </div>
    </div>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs';

export default {
    components: {
        Breadcrumbs,
    },

    data: () => ({
        items: [
            {
                text: 'home',
                href: '/'
            },
            {
                text: 'boursesEtudes',
                href: '#',
            },
            {
                text: 'scholarshipEdhec',
                href: '#',
            }
        ]
    }),
}
</script>

<style lang="scss" scoped>
.edhec-wrap {
    .edhec-header {
        h1 {
            max-width: 706px;
            font-weight: 900;
            font-size: 36px;
            line-height: 46px;
            margin-bottom: 40px;

            @media(max-width: 768px) {
                font-size: 24px;
            }
        }
    }
    .edhec-text_wrap {
        max-width: 706px;

        h3 {
            font-weight: bold;
            font-size: 22px;
            line-height: 34px;
            margin: 40px 0 15px;
        }

        .edhec-text {
            display: flex;
            margin-bottom: 4px;

            span{
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
            }
        }

        .list-number {
            margin-right: 15px;
        }

    }

    .edhec-info {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-top: 40px;

        p{
            margin-top: 35px;
        }
    }
}
</style>